import React from "react"

import {
  Delimiter,
  MainTitle,
  VideoContainer,
} from "../Catalog/PageCatalog.styled"
import Product from "../../components/Product/Product"
import Feedback from "../../components/Feedback/Feedback"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../../layouts/MainLayout/Layout"
import Header from "../../components/Header/Header"
import Wrapper from "../../components/Wrapper/Wrapper"
import Seo from "../../config-components/seo"
import Footer from "../../components/Footer/Footer"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import productsData from "../../data/products";

const Accessories = ({ location, pageContext }) => {
  const query = useStaticQuery(graphql`
    query AccessoriesQuery {
      products: allFile(
        filter: { relativeDirectory: { regex: "/images/catalog/accessories/" } }
      ) {
        edges {
          node {
            publicURL
            relativeDirectory
          }
        }
      }
    }
  `)

  const getProduct = (name) =>
    query.products.edges
      .filter(
        (el) => el.node.relativeDirectory.split("/").slice(-2)[0] === name
      )
      .map((el) => ({
        url: el.node.publicURL,
        alt: el.node.relativeDirectory.split("/").slice(-1)[0],
      }))

  const {
    breadcrumb: { crumbs },
  } = pageContext

  const {remen, vint, shesternya, rolik, polotno, veloprivod, ballonM1, zachitaMotora} = productsData

  return (
    <Layout>
      <Header page={location.pathname} />
      <Wrapper width={754}>
        <div style={{ width: "100%" }}>
          <Breadcrumb
            crumbs={crumbs}
            crumbLabel="Аксессуары"
            crumbSeparator=" > "
          />
        </div>
        <Seo
          title={
            "Купить баллон для катамарана, поплавок катамарана, педальный привод"
          }
          description={
            "Купить баллоны и поплавки к катамаранам, педальный велопривод, комплектующие и аксессуары к надувным катамаранам, моторным катамаранам MARINA-M"
          }
          keywords={
            "Купить велопривод катамарана, педальный привод катамарана, купить надувной баллон катамарана"
          }
        />
        <MainTitle
          style={{
            paddingBottom: 0,
            marginBottom: 32,
            fontWeight: "bold",
            paddingTop: 16,
            fontSize: "28px",
          }}
        >
          <h1>Аксессуары и запчасти</h1>
        </MainTitle>
        <MainTitle
          style={{
            paddingBottom: 0,
            marginBottom: 7,
            fontWeight: "bold",
            paddingTop: 50,
          }}
        >
          <h2>Ремень зубчатый велопривода</h2>
        </MainTitle>
        <Product
          images={getProduct("remen")}
          info={remen.mainInfo}
          price={remen.price}
          style={{ marginTop: 0, marginBottom: 0 }}
        />

        <MainTitle
          style={{
            paddingBottom: 0,
            marginBottom: 7,
            fontWeight: "bold",
            paddingTop: 50,
          }}
        >
          <h2>Винт велопривода в сборе с ответной шестерней</h2>
        </MainTitle>
        <Product
          images={getProduct("vint")}
          info={vint.mainInfo}
          price={vint.price}
          style={{ marginTop: 0, marginBottom: 0 }}
        />

        <MainTitle
          style={{
            paddingBottom: 0,
            marginBottom: 7,
            fontWeight: "bold",
            paddingTop: 50,
          }}
        >
          <h2>Шестерня ведущая велопривода в сборе с шатуном</h2>
        </MainTitle>
        <Product
          images={getProduct("shesternya")}
          info={shesternya.mainInfo}
          price={shesternya.price}
          style={{ marginTop: 0, marginBottom: 0 }}
        />

        <MainTitle
          style={{
            paddingBottom: 0,
            marginBottom: 7,
            fontWeight: "bold",
            paddingTop: 50,
          }}
        >
          <h2>Натяжной ролик ремня велопривода (в комплекте с эксцентриком)</h2>
        </MainTitle>
        <Product
          images={getProduct("rolik")}
          info={rolik.mainInfo}
          price={rolik.price}
          style={{ marginTop: 0, marginBottom: 0 }}
        />

        <MainTitle
          style={{
            paddingBottom: 0,
            marginBottom: 7,
            fontWeight: "bold",
            paddingTop: 50,
          }}
        >
          <h2>
            Полотно сидения + стягивающий шнур (не намокает, устойчива к
            ультрафиолету)
          </h2>
        </MainTitle>
        <Product
          images={getProduct("polotno")}
          info={polotno.mainInfo}
          price={polotno.price}
          style={{ marginTop: 0, marginBottom: 0 }}
        />

        <MainTitle
          style={{
            paddingBottom: 0,
            marginBottom: 7,
            fontWeight: "bold",
            paddingTop: 50,
          }}
        >
          <h2>
            Педальный движитель с ременной передачей для катамарана в сборе.
          </h2>
        </MainTitle>
        <Product
          images={getProduct("veloprivod")}
          info={veloprivod.mainInfo}
          price={veloprivod.price}
          style={{ marginTop: 0, marginBottom: 0 }}
        />

        <MainTitle
          style={{
            paddingBottom: 0,
            marginBottom: 7,
            fontWeight: "bold",
            paddingTop: 50,
          }}
        >
          <h2>Баллон катамарана MARINA-M1.</h2>
        </MainTitle>
        <Product
          images={getProduct("ballon-m1")}
          info={ballonM1.mainInfo}
          price={ballonM1.price}
          style={{ marginTop: 0, marginBottom: 0 }}
        />

        <MainTitle
          style={{
            paddingBottom: 0,
            marginBottom: 7,
            fontWeight: "bold",
            paddingTop: 50,
          }}
        >
          <h2>
            Защита винта лодочного (троллингового) электромотра <br /> (на любые
            модели)
          </h2>
        </MainTitle>
        <Product
          images={getProduct("zachita-motora")}
          info={zachitaMotora.mainInfo}
          price={zachitaMotora.price}
          style={{ marginTop: 0, marginBottom: 0 }}
        />

        <VideoContainer>
          <iframe
            allowFullScreen="allowfullscreen"
            src="https://www.youtube.com/embed/n8QGcN2YaO0"
            frameBorder="0"
          />
        </VideoContainer>

        <Delimiter />

        <div id={`feedback`}>
          <Feedback styles={{ marginBottom: "2rem", marginTop: "2rem" }} />
        </div>
      </Wrapper>
      <Footer />
    </Layout>
  )
}

export default Accessories
